<template>
  <div class="errorPage">
    <div>
      <img class="icon" src="@/assets/alert-circle-outline.svg" alt="" />
      <div class="header">เกิดข้อผิดพลาด!</div>
      <div class="subHeader">
        <div v-if="message" v-html="message"></div>
        <div v-else>
          กรุณาเข้าใช้งานใหม่ภายหลัง หรือติดต่อ
          <p class="word">เจ้าหน้าที่</p>
        </div>
      </div>
      <div class="footer">
        <div v-if="code">Error code: {{ code }}</div>
        <div>{{ newDate(new Date()) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { message: String, code: Number },
  methods: {
    newDate(date) {
      return new Intl.DateTimeFormat("th", {
        year: "2-digit",
        month: "2-digit",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(new Date(date));
    },
  },
};
</script>
<style lang="scss">
.errorPage {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  height: 100%;
  min-height: 100vh;
  padding: 15px;
  text-align: center;
}

.icon {
  width: 100px;
  margin: 0 auto;
}

.header {
  font-size: 24px;
}
.subHeader {
  margin-top: 10px;
  font-size: 18px;
}
.word,
w {
  display: inline-block;
}

.footer {
  margin-top: 10px;
}
</style>